import '../sass/styles.scss';
import * as PIXI from 'pixi.js';

require('pixi-spine');
import { Spine } from 'pixi-spine';

import ControllerBG from './bg/ControllerBG';
import AnimationLoader from './preloaders/animations/AnimationLoader';
import ControllerTank from './lamborghini/ControllerLamborghini';
import ControllerUI from './ui/ControllerUI';
import { requestAuth } from './api/wsRequests';
import ImagePreloader from './preloaders/images/ImagePreloader';
import OPWrapperService from 'WrapperService/index';
import SoundsPreloader from './preloaders/sounds/SoundsPreloader';
import SoundManager from './soundManager/SoundManager';
import ManagerResult from './ManagerResult';
import './utils/array';
import eSoundsType from './preloaders/sounds/eSoundsType';
import ControllerSmiles from './smiles/ControllerSmiles';
import GameScaleManager from './GameScaleManager';
import wrapperConfig from './wrapperConfig';
import WaitingBetsScreen from './waitingBets/WaitingBetsScreen';
import ManagerSFX from './ManagerSFX';
import EMAdapter from './platforms/everyMatrix/adapter';

new OPWrapperService(wrapperConfig);
window.OPWrapperService.Fullscreen.showButtonIfAndroid();

Spine.prototype.autoUpdateTransform = function () {
  if (Spine.globalAutoUpdate) {
    this.lastTime = this.lastTime || Date.now();
    var timeDelta = (Date.now() - this.lastTime) * 0.001;
    this.lastTime = Date.now();
    this.update(timeDelta);
  } else {
    this.lastTime = 0;
  }

  try {
    PIXI.Container.prototype.updateTransform.call(this);
  } catch (e) {
    // console.warn(e.message);
  }
};

PIXI.Container.prototype.updateTransform = function () {
  if (this.sortableChildren && this.sortDirty) {
    this.sortChildren();
  }
  this._boundsID++;
  this.transform.updateTransform(this.parent.transform);
  // TODO: check render flags, how to process stuff here
  this.worldAlpha = this.alpha * this.parent.worldAlpha;
  for (var i = 0, j = this.children.length; i < j; ++i) {
    var child = this.children[i];
    try {
      if (child.visible) {
        child.updateTransform();
      }
    } catch (e) {
      // console.warn(e.message);
    }
  }
};


export default class Game {
  constructor() {
    this.app = null;
    this.compiled = false;

    this.configLoaded = false;
    this.fontsLoaded = false;
    this.assetsLoaded = false;
    this.soundsLoaded = false;
    this.animationsLoaded = false;
    this.gameInitilized = false;

    const multiplier = 1;
    this.config = {
      width: 1015 * multiplier,
      height: 650 * multiplier,
    };
    PIXI.settings.FAIL_IF_MAJOR_PERFORMANCE_CAVEAT = false;

    this.start();

    EMAdapter.init();
  }

  start() {
    this.applicationOptions = {
      width: this.config.width,
      height: this.config.height,
      resolution: this.isRetinaDisplay() ? 1 : 1,
      autoResize: true
    };
    this.app = new PIXI.Application(this.applicationOptions);
    this.container = document.getElementById('container');
    this.container.appendChild(this.app.view);
    this.app.view.style.setProperty('height', '100%');
    this.app.view.style.setProperty('width', '');

    this.miniPreloader = document.getElementById('miniPreloader');

    const bundleAssets = {};

    ImagePreloader.addAssetsToBundle(bundleAssets);
    AnimationLoader.addAssetsToBundle(bundleAssets);
    SoundsPreloader.addAssetsToBundle(bundleAssets);

    PIXI.Assets.addBundle('assets', bundleAssets);

    requestAuth()
      .then(() => {
        console.log('Config loaded');
        new ControllerUI();
        this.configLoaded = true;
        this.tryInitGame();
      });
    PIXI.Assets.loadBundle('assets', this.onAssetsLoadingUpdate.bind(this))
      .then(this.onAssetsLoadingComplete.bind(this))
      .catch(this.onAssetsLoadingError.bind(this));
  }

  onAssetsLoadingError(event) {
    console.error(event);
    window.OPWrapperService.showError(window.OPWrapperService.errors.ASSETS_ERROR.CODE);
    PIXI.Assets.reset();
  }

  onAssetsLoadingComplete() {
    console.log('Assets loaded');
    this.assetsLoaded = true;
    this.tryInitGame();
  }

  onAssetsLoadingUpdate(progress) {
    progress *= 100;
    OPPreloader.setProgress(progress);
    EMAdapter.loadProgress(progress);
  }

  initScaleManager() {
    GameScaleManager.onChangeOrientation.add(({ orientation }) => {
      window.OPWrapperService.ControllerStatistic.setOrientation(orientation);
    });
    GameScaleManager.init(this.app);
  }

  tryInitGame() {
    if (this._readyForInit()) {
      this.initGame();
    }
  }

  get ready() {
    return this._readyForInit() && this.gameInitilized;
  }

  _readyForInit() {
    return (
      (
        this.assetsLoaded
      ) &&
      this.configLoaded
    );
  }

  initGame() {
    const gameWrapper = document.querySelector('.wrapper');
    gameWrapper.style.display = 'block';
    OPPreloader.hide();
    window.OPWrapperService.freeBetsController.show();
    EMAdapter.loadCompleted();
    // const main = FlashLib.createItemFromLibrary('main', 'FlashLib');
    // main.visible = false;
    // this.controllerUI = new ControllerUI(main, this.container);
    this.mainContainer = new PIXI.Container();
    // this.mainContainer.width = this.config.width;
    // this.mainContainer.height = this.config.height;
    // this.mainContainer.pivot.set(this.config.width / 2 + 100, this.config.height / 2 - 110);
    this.app.stage.addChild(this.mainContainer);

    window.controllerBG = new ControllerBG(this.mainContainer);

    new ControllerTank(this.mainContainer);
    new WaitingBetsScreen(this.mainContainer);

    const smilesContainer = new PIXI.Container();
    this.app.stage.addChild(smilesContainer);
    new ControllerSmiles(smilesContainer);

    SoundManager.init();
    ManagerSFX.init();

    ManagerResult.init();
    this.initScaleManager();
    this.gameInitilized = true;
    this.miniPreloader.style.setProperty('display', 'none');
    // window.OPPreloader.hide();
  }

  //todo: move to another place
  isRetinaDisplay() {
    let result = false;
    if (window.matchMedia) {
      let mq = window.matchMedia('only screen and (min--moz-device-pixel-ratio: 1.3), only screen and (-o-min-device-pixel-ratio: 2.6/2), only screen and (-webkit-min-device-pixel-ratio: 1.3), only screen  and (min-device-pixel-ratio: 1.3), only screen and (min-resolution: 1.3dppx)');
      result = (mq && mq.matches || (window.devicePixelRatio > 1));
    }
    return result;
  }
}

window.addEventListener('load', () => {
  new Game();

  // active state for buttons on iOS
  //https://web.dev/add-touch-to-your-site/

  if (/iP(hone|ad)/.test(window.navigator.userAgent)) {
    const elements = document.querySelectorAll('button');
    const emptyFunction = function () {
    };

    for (let i = 0; i < elements.length; i++) {
      elements[i].addEventListener('touchstart', emptyFunction, false);
    }
  }
});
